import React from "react"
import { Layout } from "../components/common";
import SEO from "../components/Seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRTF } from "../components/rich-text"

import PostImage from "../components/blog/post-image";

const AuthorTemplate = ({ data }) => {
  
  const author = data.author.edges[0].node
  const posts = author.post

  const authorImage = getImage(author.avatar)

  return (
    <Layout purple>
      <SEO 
        title={`Author ${author.firstName} ${author.lastName ? author.lastName : ''}`} 
        description={`Bio and Posts by ${author.firstName} ${author.lastName}`}
        canonical 
      />
      
      <header className="pt-20 lg:pt-24 pb-4 lg:pb-6">
        <div className="container max-w-2xl text-center">
          
          <div className="flex flex-col justify-center items-center -mx-2">
            <div className="px-2 w-48 h-48 relative rounded-full flex items-center">
              <GatsbyImage
                className={`rounded-full w-full object-contain`}
                image={authorImage}
                layout="fixed"
                alt={`${author.firstName} ${author.lastName}`}
              />
            </div>
            <div className="px-2 text-lg md:text-xl">
              <div className="font-bold">
              <h1 className="font-semibold text-grey-400">
                {author.firstName}
                {" "}
                {author.lastName}
              </h1>
              </div>
              <div className="opacity-60 leading-snug">{data.author.title}</div>
            </div>
          </div>
          <div>
            {author.bio && renderRTF(author.bio)}
          </div>
        </div>
      </header>

      {/* {JSON.stringify(author)} */}

      <section className="sm:pt-10 pt-20">
        <div className="container mx-auto relative pb-10">
          <div className="flex flex-wrap -mx-4">
            {posts && posts.map(i => {
            return (
              <a 
                href={`/blog/${i.slug}`}
                className="px-4 w-full md:w-1/2 lg:w-1/4 my-6 hover:opacity-80">
                <div className="w-full relative overflow-hidden aspect-w-16 aspect-h-9 bg-cx">
                  <div className="absolute inset-0">
                    {i.image && <PostImage post={i} />}
                  </div>
                </div> 
                <p className="text-white font-medium mt-4 mb-3">{i.heading}</p>
                <p className="text-sm font-bold hidden text-grey-400">{i.createdAt}</p>
              </a>
            )}
            )}
          </div>
        </div>
      </section>

    </Layout>
  )
}
export default AuthorTemplate

export const AuthorPageQuery = graphql`
  query AuthorPagesQuery($id: String!) {
    
    author: allContentfulAuthor(
      filter: {
        id: { eq: $id }
      }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          id
          slug
          title
          lastName
          firstName
          name
          bio {
            raw
          }
          avatar {
            gatsbyImageData(
              width: 192
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          post {
            id
            heading
            date(formatString: "MMMM DD yyyy")
            tags {
              slug
              title
              id
            }
            createdAt(formatString: "MMMM DD yyyy")
            slug
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
